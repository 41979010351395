import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import useSpotify, {SongItem} from "../Other/useSpotify";

interface SongsTableProps {
    songs: SongItem[],
    loading: boolean
}

const SongsTable = ({songs, loading}: SongsTableProps) => {

    const {formatTime} = useSpotify();

    return (
        <Box sx={{position: "relative"}}>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 500}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Tempo</TableCell>
                            <TableCell align="right">Start Time</TableCell>
                            <TableCell align="right">Duration</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {songs.map((song) => (
                            <TableRow
                                key={song.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {song.name}
                                </TableCell>
                                <TableCell align="right">{Math.round(song.tempo)}</TableCell>
                                <TableCell align="right">{formatTime(song.startTime)}</TableCell>
                                <TableCell align="right">{formatTime(song.duration)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {loading && <Box sx={{
                position: "absolute",
                top: "0px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                opacity: 0.67
            }}>
                <CircularProgress/>
            </Box>}

        </Box>
    )
}

export default SongsTable;
