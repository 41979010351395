import {SyntheticEvent, useEffect, useState} from "react";
import {Box, Button, Tab, Tabs, TextField, Typography} from "@mui/material";
import useFirebase from "../Other/useFirebase";
import {SongItem, PlaylistInfo} from "../Other/useSpotify";
import SongsTable from "../Components/SongsTable";
import LoadingButton from "@mui/lab/LoadingButton";
import Line from "../Components/Line";

const DanceSongs = () => {

    const {getSongs, getRandom, getPlaylistInfo, handleUpdateDatabase} = useFirebase();

    const [songs, setSongs] = useState<SongItem[]>([]);
    const [playlist, setPlaylist] = useState<PlaylistInfo>();
    const [loadingSongs, setLoadingSongs] = useState(false);
    const [updatingDatabase, setUpdatingDatabase] = useState(false);
    const [tabOpen, setTabOpen] = useState(0);
    const [token, setToken] = useState<string | null | undefined>(window.localStorage.getItem("token"));

    const dances = ["SAMBA", "CHACHA", "RUMBA", "PASO DOBLE", "JIVE"];

    const handleGetSongs = (dance: string) => {

        if (!token)
            return

        setLoadingSongs(true)
        getSongs({collection: dance}).then(response => {
            setSongs(response.data as SongItem[]);
            setLoadingSongs(false);
        })
    }

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setTabOpen(newValue);
        handleGetSongs(dances[newValue]);
        handlePlaylistInfo(dances[newValue]);
    }

    const handleAddPlaylist = () => {
        const link = "https://open.spotify.com/playlist/3cvJ29zVGwAJAtI4yx1zyN";
        let result: RegExpMatchArray | null;

        if (link.includes("?")) {
            result = link.match("(?<=playlist\\/).*(?=\\?)");
        } else {
            result = link.match("(?<=playlist\\/).*");
        }

        console.log(result);
    }

    const handlePlaylistInfo = (dance: string) => {
        //handleAddPlaylist();
        /*getRandom({collection: dances[0]}).then(response => {
            console.log(response.data)
        })*/

        if (token) {
            getPlaylistInfo({dance: dance, token: token}).then(response => {
                const data = response.data as any;

                const playlistItem: PlaylistInfo = {
                    id: data.id,
                    public: data.public,
                    name: data.name,
                    owner: data.owner.display_name,
                    tracks: data.tracks.total,
                    image: data.images.length > 1 ? data.images[1].url : data.images[0].url
                }

                setPlaylist(playlistItem);
            })
        }
    }

    const handleUpdateSongsInDatabase = () => {
        setUpdatingDatabase(true);

        if (token && playlist)
            handleUpdateDatabase({dance: dances[tabOpen], playlistID: playlist.id, token: token}).then(response => {
                handleGetSongs(dances[tabOpen]);
                setUpdatingDatabase(false);
            })
    }

    useEffect(() => {
        handleGetSongs(dances[0]);
        handlePlaylistInfo(dances[0]);
    }, []);

    return (
        <Box sx={{margin: "25px"}}>
            <h1>Tantsulaulud</h1>

            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "20px",
                margin: "25px 0"
            }}>
                <LoadingButton loading={updatingDatabase} variant="contained" onClick={handleUpdateSongsInDatabase}>Update Song Database</LoadingButton>
                {/*<Box>
                    <TextField id="addSongLink" label="Spotify song link.." size="small" variant="outlined"/>
                    <Button variant="contained" sx={{marginLeft: "10px", height: "40px"}}>Add</Button>
                </Box>*/}
            </Box>

            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabOpen} onChange={handleTabChange}>
                    {dances.map((dance, id) =>
                        <Tab key={id} label={dance} id={id.toString()}/>
                    )}
                </Tabs>
            </Box>

            <Box sx={{display: "flex", margin: "25px 0"}}>
                <Box component="img" src={playlist?.image} width={150} height={150}/>
                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "20px"}}>
                    <Typography variant="overline">{playlist?.public === true ? "AVALIK" : "PRIVAATNE"}</Typography>
                    <Typography variant="h5">{playlist?.name}</Typography>
                    <Typography variant="body2">{playlist?.owner}</Typography>
                    <Typography variant="body2">{playlist?.tracks} laulu</Typography>
                </Box>
                {songs.length && <Line songs={songs}/>}
            </Box>

            <SongsTable songs={songs} loading={loadingSongs}/>
        </Box>
    )
}

export default DanceSongs;
