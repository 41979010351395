import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {Collapse, Divider, IconButton} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import useWindowDimensions from "../Other/useWindowDimensions";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExpand, faXmark, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {Outlet, Link} from "react-router-dom";

const SideDrawer = () => {

    // const menuItems = ["HomeUI", "RMK platsid", "Orienteerumine", "3D printimine"];
    // "E-vedelikud"

    const {width} = useWindowDimensions();
    const small = width < 600;
    const drawerWidth = small ? 180 : 240;

    const logToElement = false;

    const [isOrientationMenuOpen, setOrientationMenuOpen] = useState(false);
    const [isDanceMenuOpen, setDanceMenuOpen] = useState(false);
    const [view, setView] = useState(sessionStorage.getItem("view"));

    const handleOrientationMenuClick = () => {
        setOrientationMenuOpen(!isOrientationMenuOpen);
    };

    const handleDanceMenuClick = () => {
        setDanceMenuOpen(!isDanceMenuOpen);
    };

    useEffect(() => {
        const item = sessionStorage.getItem("view");

        if (item === "fullscreen") {
            handleMaximize();
        } else {
            handleMinimize();
        }

        window.dispatchEvent(new Event("resize"));

    }, [view]);

    const handleSetView = (str: string) => {
        sessionStorage.setItem("view", str);
        setView(str);
    }

    const handleMaximize = () => {
        const header = document.querySelector("header");
        const drawer = document.querySelector("#drawer");
        const main = document.querySelector("main");

        if (header && drawer && main) {
            header.classList.add("hidden");
            drawer.classList.add("hidden");
            main.classList.add("main-fullscreen");
        }
    }

    const handleMinimize = () => {
        const header = document.querySelector("header");
        const drawer = document.querySelector("#drawer");
        const main = document.querySelector("main");

        if (header && drawer && main) {
            header.classList.remove("hidden");
            drawer.classList.remove("hidden");
            main.classList.remove("main-fullscreen");
        }
    }

    (function () {
        const logger = document.getElementById('debug') as HTMLElement;

        if(!logToElement)
            return

        if(!logger) {
            console.log("No element found to log into")
            return
        }

        console.log = function (message) {
            if (typeof message == 'object') {
                logger.innerHTML += (JSON && JSON.stringify ? JSON.stringify(message) : message) + '<br />';
            } else {
                logger.innerHTML += message + '<br />';
            }
        }
    })();

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
                    <Box component={Link} to={"/"} sx={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "20px"
                    }}>traaga.ee</Box>

                    <IconButton onClick={() => handleSetView("fullscreen")}
                                sx={{width: small ? "40px" : "56px", height: small ? "40px" : "56px", right: "-10px"}}>
                        <FontAwesomeIcon color={"white"} size={small ? "1x" : "lg"} icon={faExpand}/>
                    </IconButton>
                    {view === "fullscreen" &&
                        <IconButton id="minimize-button" onClick={() => handleSetView("default")}
                                    sx={{
                                        width: small ? "40px" : "56px",
                                        height: small ? "40px" : "56px",
                                        right: small ? "-10px" : "-20px",
                                        visibility: "visible"
                                    }}>
                            <FontAwesomeIcon color={"black"} size={small ? "1x" : "lg"} icon={faXmark}/>
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                id="drawer"
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                }}
            >
                <Toolbar/>
                <Box sx={{overflow: 'auto'}}>
                    <List>
                        <ListItem disablePadding component={Link} to={"/homeui"} sx={{color: "#272727"}}>
                            <ListItemButton>
                                <ListItemText primary={"HomeUI"}/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding component={Link} to={"/camping"} sx={{color: "#272727"}}>
                            <ListItemButton>
                                <ListItemText primary={"RMK platsid"}/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={handleOrientationMenuClick}>
                                <ListItemText primary={"Orienteerumine"}/>
                                {isOrientationMenuOpen ? <FontAwesomeIcon icon={faChevronUp}/> :
                                    <FontAwesomeIcon icon={faChevronDown}/>}
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={isOrientationMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem component={Link} to={"/orientation"}
                                          sx={{color: "#272727", padding: "0px 16px"}}>
                                    <ListItemButton>
                                        <ListItemText primary={"Kaart"}/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem component={Link} to={"/arrow"}
                                          sx={{color: "#272727", padding: "0px 16px"}}>
                                    <ListItemButton>
                                        <ListItemText primary={"Nool"}/>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Collapse>

                        <ListItem disablePadding component={Link} to={"/projects"} sx={{color: "#272727"}}>
                            <ListItemButton>
                                <ListItemText primary={"3D printimine"}/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={handleDanceMenuClick}>
                                <ListItemText primary={"Tants"}/>
                                {isDanceMenuOpen ? <FontAwesomeIcon icon={faChevronUp}/> :
                                    <FontAwesomeIcon icon={faChevronDown}/>}
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={isDanceMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem component={Link} to={"/player"}
                                          sx={{color: "#272727", padding: "0px 16px"}}>
                                    <ListItemButton>
                                        <ListItemText primary={"Spotify"}/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem component={Link} to={"/songs"}
                                          sx={{color: "#272727", padding: "0px 16px"}}>
                                    <ListItemButton>
                                        <ListItemText primary={"Laulud"}/>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Collapse>

                        <ListItem id="version">v0.0.1</ListItem>

                        <ListItem id="debug"></ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box component="main"
                 sx={{
                     flexGrow: 1,
                     p: 3,
                     padding: 0,
                     height: small ? "calc(100vh - 56px)" : "calc(100vh - 64px)",
                     width: small ? "calc(100vw - 180px)" : "calc(100vw - 240px)",
                     overscrollBehavior: "none",
                     position: "fixed",
                     left: small ? "180px" : "240px",
                     top: small ? "56px" : "64px",
                     overflow: "auto"
                 }}>
                <Outlet/>
            </Box>
        </Box>
    );
}

export default SideDrawer;
