import {useState, useEffect} from "react";
import {
    Button,
    Box,
    Typography,
    IconButton,
    LinearProgress,
    FormControl,
    MenuItem,
    Select,
    InputLabel, SelectChangeEvent
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';

import useSpotify, {PlayBackState} from "../Other/useSpotify";
import useWindowDimensions from "../Other/useWindowDimensions";

const startSilentTrackURI = "spotify:track:2io7fd0NRzuNMmPMyRDDMl";
const silentTrackURI = "spotify:track:7p5bQJB4XsZJEEn6Tb7EaL";

// https://developer.spotify.com/console/get-audio-features-track/

const latinFinal = [
    "spotify:track:7gqJhoWQHAJAelP9YJSKcT", // Samba
    silentTrackURI,
    "spotify:track:1zlYyj5ODwXtoFcLBz0i0m", // Cha Cha
    silentTrackURI,
    "spotify:track:6C7hewznjDWLUlBdnQ8zHo", // Rumba
    silentTrackURI,
    "spotify:track:3JY8ajhJeQKPRxiXxSVWA3", // Paso Doble
    silentTrackURI,
    "spotify:track:7BK8wsV43dqfZaZN2SP45s" // Jive
]

interface Final {
    count: number,
    pauseDuration: number,
    songDuration: number,
}

const SpotifyPlayer = () => {

    const {
        token,
        setToken,
        playBackState,
        setPlayBackState,
        fetchSpotify,
        logInButton,
        logOutButton,
        formatTime
    } = useSpotify();

    const {width} = useWindowDimensions();

    const [rumbaLoading, setRumbaLoading] = useState(false);
    const [finalLoading, setFinalLoading] = useState(false);
    const [checkFinalSongProgress, setCheckFinalSongProgress] = useState(false);

    const songDurations = Array.from({length: 37}, (v, k) => k * 5000);
    const pauseDurations = Array.from({length: 13}, (v, k) => k * 5000);

    const [final, setFinal] = useState<Final>({
        count: 1,
        pauseDuration: 20000, // 0:20 - 20000
        songDuration: 105000 // 1:45 - 105000
    });

    useEffect(() => {
        const hash = window.location.hash;
        let token = window.localStorage.getItem("token") as string | null | undefined;

        if (!token && hash) {
            token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token"))?.split("=")[1];

            window.location.hash = "";
            window.localStorage.setItem("token", token ? token : "");
        }

        if (token)
            setToken(token);

    }, [])

    useEffect(() => {
        let intervalId: NodeJS.Timer;

        if (token) {
            intervalId = setInterval(() => {
                getPlayBackState();
            }, 1000)
        } else
            setPlayBackState(null);

        return (() => {
            clearInterval(intervalId)
        })
    }, [token])


    const getPlayBackState = () => {
        fetchSpotify("me/player", "GET")
            .then(data => {
                const artists = data.item.artists.map((artist: any) => artist.name).join(", ")

                const state: PlayBackState = {
                    timestamp: data.timestamp,
                    isPlaying: data.is_playing,
                    progress: data.progress_ms,
                    duration: data.item.duration_ms,
                    trackId: data.item.id,
                    trackUri: data.item.uri,
                    trackName: data.item.name,
                    trackArtists: artists,
                    trackAlbumImage: data.item.album.images[1].url
                }

                setPlayBackState(state);
            })
    }

    const play = () => {
        fetchSpotify("me/player/play", "PUT")
            .then(() => {
                getPlayBackState();
            })
    }

    const pause = () => {
        fetchSpotify("me/player/pause", "PUT")
            .then(() => {
                getPlayBackState();
            })
    }

    const togglePlay = () => {
        if (playBackState?.isPlaying)
            pause();
        else
            play();
    }

    const skipNext = () => {
        fetchSpotify("me/player/next", "POST");
    }

    const skipPrevious = () => {
        if (!playBackState)
            return

        if (playBackState.progress > 5000)
            fetchSpotify(`me/player/seek?position_ms=0`, "PUT");
        else
            fetchSpotify("me/player/previous", "POST");
    }

    const forward = () => {
        if (!playBackState)
            return

        let newPosition = playBackState.progress + 10000;

        fetchSpotify(`me/player/seek?position_ms=${newPosition}`, "PUT");
    }

    const reverse = () => {
        if (!playBackState)
            return

        let newPosition = playBackState.progress - 10000;

        if (newPosition < 0)
            newPosition = 0;

        fetchSpotify(`me/player/seek?position_ms=${newPosition}`, "PUT");
    }

    const getProgressBarValue = () => {
        if (!playBackState)
            return 0;

        return playBackState.progress / playBackState.duration * 100;
    }

    const playRandomRumbaSong = () => {
        const rumbaPlaylistID = "6POPKHZq6u0pHb4XVLm57V";

        setRumbaLoading(true);

        fetchSpotify(`playlists/${rumbaPlaylistID}/tracks`, "GET").then(data => {
            const trackItem = data.items[Math.floor(Math.random() * data.items.length)];
            const songURI = trackItem.track.uri;

            const rumbaData = {
                context_uri: `spotify:playlist:${rumbaPlaylistID}`,
                offset: {uri: songURI}
            }

            fetchSpotify("me/player/play", "PUT", rumbaData)
                .then(() => {
                    setRumbaLoading(false)
                })
        })
    }

    const playLatinFinal = async () => {
        setFinalLoading(true);

        fetchSpotify("me/player/play", "PUT", {uris: [startSilentTrackURI]});

        for (const uri of latinFinal) {
            await fetchSpotify(`me/player/queue?uri=${uri}`, "POST");
        }

        setFinalLoading(false);
        setCheckFinalSongProgress(true);
        skipNext();
    }

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (!checkFinalSongProgress && playBackState && final) {
            const index = latinFinal.indexOf(playBackState.trackUri);
            setPlayBackState(undefined);

            if (index !== latinFinal.length - 1) {
                skipNext();
                setCheckFinalSongProgress(true);
            } else {
                pause();
                timeout = setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }

        return () => clearTimeout(timeout)

    }, [checkFinalSongProgress])

    if (checkFinalSongProgress && playBackState && final && playBackState.isPlaying) {
        const index = latinFinal.indexOf(playBackState.trackUri);

        if (index !== -1) {
            if (playBackState.progress > final.songDuration && index !== 6 && index % 2 === 0) {
                setCheckFinalSongProgress(false);
            } else if (playBackState.progress > 77000 && index === 6) {
                setCheckFinalSongProgress(false);
            } else if (playBackState.progress > final.pauseDuration && index % 2 === 1) {
                setCheckFinalSongProgress(false);
            }
        }
    }

    const protectedContent = () => {

        return (
            <>
                <Box sx={{marginTop: "25px", display: "flex", gap: "20px", flexWrap: "wrap"}}>
                    {logOutButton()}
                    <LoadingButton
                        loading={rumbaLoading}
                        onClick={playRandomRumbaSong}
                        variant="contained"
                    >
                        Random rumba
                    </LoadingButton>
                    <LoadingButton
                        loading={finalLoading}
                        onClick={playLatinFinal}
                        variant="contained"
                    >
                        Start Latin Final
                    </LoadingButton>

                    <Button variant="contained" onClick={() => window.location.reload()}>Refresh</Button>
                </Box>

                <Box sx={{display: "flex", justifyContent: width < 700 ? "center" : "inherit", margin: "50px 0"}}>
                    <Box sx={{width: "300px"}}>
                        <Box component="img" src={playBackState ? playBackState.trackAlbumImage : "album-cover.jpg"}
                             sx={{width: "100%", marginBottom: "10px"}}/>

                        <Box>
                            <Typography variant="subtitle2" sx={{fontWeight: "bold"}}>
                                {playBackState ? playBackState.trackName : "Song Name"}
                            </Typography>
                            <Typography variant="caption">
                                {playBackState ? playBackState.trackArtists : "Artists"}
                            </Typography>
                        </Box>

                        <Box sx={{marginTop: "20px"}}>
                            <LinearProgress variant="determinate" value={getProgressBarValue()}/>
                        </Box>

                        <Box sx={{display: "flex", justifyContent: "space-between"}}>
                            <Typography variant="caption" sx={{fontSize: "0.7rem", opacity: "0.67"}}>
                                {formatTime(playBackState?.progress)}
                            </Typography>
                            <Typography variant="caption" sx={{fontSize: "0.7rem", opacity: "0.67"}}>
                                -{playBackState && formatTime(playBackState.duration - playBackState.progress)}
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                            <IconButton onClick={skipPrevious} color={"primary"}><SkipPreviousIcon
                                fontSize="large"/></IconButton>
                            <IconButton onClick={reverse} color={"primary"}><FastRewindIcon
                                fontSize="large"/></IconButton>

                            <IconButton onClick={togglePlay} color={"primary"}>
                                {playBackState?.isPlaying ? <PauseIcon fontSize="large"/> :
                                    <PlayArrowIcon fontSize="large"/>}
                            </IconButton>

                            <IconButton onClick={forward} color={"primary"}><FastForwardIcon
                                fontSize="large"/></IconButton>
                            <IconButton onClick={skipNext} color={"primary"}><SkipNextIcon
                                fontSize="large"/></IconButton>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{display: "flex", gap: "20px", flexWrap: "wrap"}}>
                    <Box sx={{width: 150}}>
                        <FormControl fullWidth>
                            <InputLabel id="song-duration-label">Song Duration</InputLabel>
                            <Select
                                labelId="song-duration-label"
                                value={final.songDuration.toString()}
                                label="Song Duration"
                                onChange={(event: SelectChangeEvent) => {
                                    setFinal({...final, songDuration: +event.target.value})
                                }}
                            >
                                {songDurations.map(el =>
                                    <MenuItem key={el} value={el}>{formatTime(el)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{width: 150}}>
                        <FormControl fullWidth>
                            <InputLabel id="pause-duration-label">Pause Duration</InputLabel>
                            <Select
                                labelId="pause-duration-label"
                                value={final.pauseDuration.toString()}
                                label="Pause Duration"
                                onChange={(event: SelectChangeEvent) => {
                                    setFinal({...final, pauseDuration: +event.target.value})
                                }}
                            >
                                {pauseDurations.map(el =>
                                    <MenuItem key={el} value={el}>{formatTime(el)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

            </>
        )
    }

    return (
        <Box sx={{margin: "25px"}}>
            <Typography variant="h4">Spotify Dance App</Typography>
            {!token ? logInButton() : protectedContent()}
        </Box>
    );
};

export default SpotifyPlayer;
