import markers from "../data.json";

export default function useLocation() {

    const locationListener = (successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null | undefined) => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({name: "geolocation"})
                .then(result => {
                    //const el = document.querySelector("#debug");
                    //if (el) el.innerHTML = result.state;

                    if (result.state === "granted") {
                        //if (el) el.innerHTML = "Fetching location..";
                        navigator.geolocation.watchPosition(successCallback, errorCallback ? errorCallback : handleLocationError, {
                            enableHighAccuracy: true,
                            timeout: 5000
                        });
                    } else if (result.state === "prompt") {
                        console.log("User is prompted to allow location", result.state);
                    } else if (result.state === "denied") {
                        console.error("Location permission denied by user", result.state);
                    }
                });
        } else {
            console.log("Geolocation is not supported by this browser");
        }
    }

    const handleLocationError = (error: GeolocationPositionError) => {
        const el = document.querySelector("#debug");
        if (el)
            el.innerHTML = error.message;
    }

    const calculatePointHeading = (location: number[], nextPoint: number[]) => {

        // vector length 0.00015 - 0.0002  longitude? 58 vertical

        //const nextPoint = [26.70889784846604, 58.372698636433775]; // point 55
        //const nextPoint = findNearestPoint(location);

        const pointVector = [nextPoint[0] - location[0], nextPoint[1] - location[1]];

        const pointVectorLength = Math.sqrt(Math.pow(pointVector[0], 2) + Math.pow(pointVector[1], 2));

        let angle = Math.acos(pointVector[1] / pointVectorLength) * 180 / Math.PI;

        if(location[0] > nextPoint[0])
            angle = 360 - angle;

        return angle;
    }

    const calculateDirection = (location: number[], heading: number) => {

        const topPos = [26.70240447057014, 58.37294906693026];
        const bottomPos = [26.70240447057014, 58.37220079135801];

        const vectorB = [0, topPos[1] - bottomPos[1]];

        const headingX = vectorB[0] * Math.cos(heading * Math.PI / 180) - vectorB[1] * Math.sin(heading * Math.PI / 180);
        const headingY = vectorB[0] * Math.sin(heading * Math.PI / 180) + vectorB[1] * Math.cos(heading * Math.PI / 180);

        return [headingX, headingY];
    }

    const calculateDistance = (pointA: number[], pointB: number[]) => {
        const vector = [pointA[0] - pointB[0], pointA[1] - pointB[1]];
        return Math.sqrt(Math.pow(vector[0],2) + Math.pow(vector[1],2));
    }

    const findNearestPoint = (location: number[]) => {
        let nearestPoint: number[] = [];
        let smallestDistance = 100;

        markers.forEach(marker => {
            const point = [marker.latitude, marker.longitude];
            const distance = calculateDistance(location, point);
            if(distance < smallestDistance) {
                smallestDistance = distance;
                nearestPoint = point;
            }
        });

        return nearestPoint;
    }

    return {locationListener, calculatePointHeading, calculateDirection, calculateDistance, findNearestPoint};
}
