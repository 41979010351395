import {useMap, useMapEvent} from "react-leaflet";

const MapCenterHandler = () => {
    const map = useMap();

    useMapEvent("moveend", () => {
        const center = map.getCenter();
        sessionStorage.setItem("centerLongitude", String(center.lng));
        sessionStorage.setItem("centerLatitude", String(center.lat));
    })

    return <></>;
}

export default MapCenterHandler;
