import React from 'react';
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import SideDrawer from "./Components/SideDrawer";
import Default from "./Pages/Default";
import NoPage from "./Pages/NoPage";
import HomeUI from "./Pages/HomeUI";
import Camping from "./Pages/Camping";
import Orientation from "./Pages/Orientation";
import Projects from "./Pages/Projects";
import Arrow from "./Pages/Arrow";
import Final from "./Pages/Final";
import SpotifyPlayer from "./Pages/SpotifyPlayer";
import DanceSongs from "./Pages/DanceSongs";


const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SideDrawer/>}>
                    <Route index element={<Default/>} />
                    <Route path="homeui" element={<HomeUI/>} />
                    <Route path="camping" element={<Camping/>} />
                    <Route path="orientation" element={<Orientation/>} />
                    <Route path="projects" element={<Projects/>} />
                    <Route path="arrow" element={<Arrow/>} />
                    <Route path="player" element={<SpotifyPlayer/>} />
                    <Route path="songs" element={<DanceSongs/>} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
