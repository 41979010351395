import html2canvas from 'html2canvas';
import { renderToStaticMarkup } from "react-dom/server"

export default function useMarkerCreator() {

    const markerTemplate = (label: string, pinColor: string, numberColor: string) => {

        return (
            <div style={{position: "relative", width: "24px", height: "32px", top: "-24px", left: "-6px"}}>
                <div style={{position: "absolute", width: "24px", display: "flex", justifyContent: "center"}}>
                    <div style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        paddingTop: "2px",
                        color: numberColor,
                        fontFamily: "sans-serif",
                        zIndex: 5
                    }}>{label}</div>
                </div>
                <img className={pinColor} src={"location-pin-solid.svg"} alt={"pin"} width={"24px"} height={"32px"}/>
            </div>
        )
    }

    const createElementFromHTML = (htmlString: string) => {
        let div = document.createElement("div");
        div.innerHTML = htmlString.trim();
        return div.firstChild as HTMLElement;
    };

    const markerHTMLElement = ({label="", pinColor="bluePin", numberColor="white"}) => {
        const elementString = renderToStaticMarkup(markerTemplate(label, pinColor, numberColor));
        return createElementFromHTML(elementString);
    }

    const downloadImage = (imageData: string) => {
        const link = document.createElement('a');
        link.download = 'download.png';
        link.href = imageData;
        link.click();
        link.remove();
    };

    const handleCreateMarker = async () => {

        const element = markerHTMLElement({});

        document.body.appendChild(element);

        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        console.log(data);
        //downloadImage(data);

        document.body.removeChild(element);
    };

    return {markerHTMLElement};
};
