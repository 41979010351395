import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material';
import App from './App';
import {green} from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2", // MUI blue
        },
        secondary: {
            main: green[500],
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);
