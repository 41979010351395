import {useEffect, useState} from "react";
import {Marker, Polyline, Popup} from "react-leaflet";
import {divIcon, LatLngExpression} from "leaflet";
import useMarkerCreator from "../Other/useMarkerCreator";
import useLocation from "../Other/useLocation";

const UserLocation = () => {
    const [position, setPosition] = useState<LatLngExpression>([26.707164030775488, 58.37405661164885]);
    const [geoLoc, setGeoLoc] = useState<GeolocationPosition>();
    const [polyline, setPolyline] = useState<LatLngExpression[][]>([]);
    const [nextPointPolyline, setNextPointPolyline] = useState<LatLngExpression[][]>([]);

    const {markerHTMLElement} = useMarkerCreator();
    const {locationListener, calculatePointHeading, calculateDirection, findNearestPoint} = useLocation();

    let counter = 0;

    const handleLocation = (position: GeolocationPosition) => {
        console.log(position);

        const el = document.querySelector("#debug");
        if (el)
            el.innerHTML = "Acc: " + position.coords.accuracy
                + "<br>Lng: " + position.coords.longitude
                + "<br>Lat: " + position.coords.latitude
                + "<br>Hdg: " + position.coords.heading
                + "<br>Cnt: " + counter;

        setGeoLoc(position);
        counter += 1;

        if (position.coords.longitude && position.coords.latitude) {
            const location = [position.coords.latitude, position.coords.longitude];
            setPosition(location as unknown as LatLngExpression);

            if (position.coords.heading) {
                const direction = calculateDirection(location, position.coords.heading - 90);
                const nextPoint = findNearestPoint(location);
                const pointDirection = calculateDirection(location, calculatePointHeading(location, nextPoint));

                const dirX = location[0] + direction[0] as unknown as LatLngExpression;
                const dirY = location[1] + direction[1] as unknown as LatLngExpression;

                const pointDirX = location[0] + pointDirection[0] as unknown as LatLngExpression;
                const pointDirY = location[1] + pointDirection[1] as unknown as LatLngExpression;

                setPolyline([location as unknown as LatLngExpression[], [dirX, dirY]]);
                setNextPointPolyline([location as unknown as LatLngExpression[], [pointDirX, pointDirY]]);
            }
        }
    }

    useEffect(() => {
        locationListener(handleLocation);
    });

    const redOptions = {color: 'red'}
    const blueOptions = {color: 'blue'}

    return position === null ? null : (
        <>
            <Polyline pathOptions={redOptions} positions={polyline}/>
            <Polyline pathOptions={blueOptions} positions={nextPointPolyline}/>
            <Marker position={position}
                    icon={divIcon({className: "custom icon", html: markerHTMLElement({pinColor: "redPin"})})}>
                <Popup>
                    You are here. <br/>
                    Accuracy: {geoLoc?.coords.accuracy} <br/>
                    Altitude: {geoLoc?.coords.altitude} <br/>
                    AltitudeAccuracy: {geoLoc?.coords.altitudeAccuracy} <br/>
                    Heading: {geoLoc?.coords.heading} <br/>
                    Latitude: {geoLoc?.coords.latitude} <br/>
                    Longitude: {geoLoc?.coords.longitude} <br/>
                    Speed: {geoLoc?.coords.speed} <br/>
                    Timestamp: {geoLoc?.timestamp}
                </Popup>
            </Marker>
        </>
    );
}

export default UserLocation;
