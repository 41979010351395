import {Box} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons';
import useLocation from "../Other/useLocation";
import {useEffect, useState} from "react";

const Arrow = () => {

    const {locationListener, calculatePointHeading, findNearestPoint, calculateDistance} = useLocation();
    const [deltaAngle, setDeltaAngle] = useState(-90);
    let counter = 0;

    const calculateDirection = (location: number[], heading: number) => {
        //const heading = 245;
        const destination = [26.72040755888122, 58.372829178266365]; // Point 19
        //const location: LatLngExpression | undefined = [26.7105729, 58.3723598]; // Home

        const topPos = [26.685870460367966, 58.38552519427828];
        const bottomPos = [26.685870460367966, 58.36566734070077];

        const vectorA = [destination[0] - location[0], destination[1] - location[1]];
        const vectorB = [0, topPos[1] - bottomPos[1]];

        const vectorALength = Math.pow(Math.pow(vectorA[0], 2) + Math.pow(vectorA[1], 2), 0.5);
        const vectorBLength = Math.pow(Math.pow(vectorB[0], 2) + Math.pow(vectorB[1], 2), 0.5);

        const product = vectorA[0] * vectorB[0] + vectorA[1] * vectorB[1];
        const angle = Math.asin(product / (vectorALength * vectorBLength)) * 180/Math.PI;

        const deltaAngle = angle - heading + 90; // not sure why +90

        console.log(deltaAngle);
        return deltaAngle;
    }

    const handleLocation = (position: GeolocationPosition) => {

        const el = document.querySelector("#debug");
        if (el)
            el.innerHTML = "Acc: " + position.coords.accuracy
                + "<br>Lng: " + position.coords.longitude
                + "<br>Lat: " + position.coords.latitude
                + "<br>Hdg: " + position.coords.heading
                + "<br>Cnt: " + counter;

        counter += 1;

        if(!position.coords.heading) {
            console.log("No heading available");
            setDeltaAngle(-90);
            return;
        }

        if(position.coords.latitude && position.coords.longitude) {
            //const newDeltaAngle = calculateDirection([position.coords.longitude, position.coords.latitude], position.coords.heading);
            const location = [position.coords.latitude, position.coords.longitude];
            const nextPoint = findNearestPoint(location);
            const newDeltaAngle = calculatePointHeading(location, nextPoint) - position.coords.heading;

            const arrowEl = document.querySelector("#arrow");

            const distance = calculateDistance(location, nextPoint);

            if(arrowEl && distance < 0.00085) // user is in 50m range of next point
                arrowEl.classList.add("greenPin");
            else if (arrowEl)
                arrowEl.classList.remove("greenPin");

            setDeltaAngle(newDeltaAngle - 90);
        }
    }

    useEffect(() => {
        locationListener(handleLocation);
    });

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
            <img id={"arrow"} src={"arrow.svg"} width={"50%"} style={{rotate: deltaAngle + "deg"}} alt={"arrow"}/>
        </Box>
    )
}

export default Arrow;
