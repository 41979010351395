import {Box, Button, ButtonProps} from "@mui/material";
import {useState} from "react";

export interface PlayBackState {
    timestamp: number,
    isPlaying: boolean,
    progress: number,
    duration: number,
    trackId: number,
    trackUri: string,
    trackName: string,
    trackArtists: string[],
    trackAlbumImage: string
}

export interface SongItem {
    id: string,
    name: string,
    tempo: number,
    startTime: number,
    duration: number
}

export interface PlaylistInfo {
    id: string,
    public: boolean,
    name: string,
    owner: string,
    tracks: number,
    image: string
}

export default function useSpotify() {

    const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

    const CLIENT_ID = "597fee46e8ca4240b521c9ad76201f15";
    const CLIENT_SECRET = "b52ce25bda9c4d6dbf0e2c254659c8bf";

    // When chnaged also add to Spotify API project settings
    // https://developer.spotify.com/dashboard/applications
    const REDIRECT_URI = isDevelopment ? "http://localhost:3000/player" : "https://traaga.ee/player";

    const SCOPE = "user-read-playback-state user-modify-playback-state playlist-read-private playlist-read-collaborative";
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
    const RESPONSE_TYPE = "token";

    const [token, setToken] = useState<string | null | undefined>("");
    const [playBackState, setPlayBackState] = useState<PlayBackState | null>();

    const fetchSpotify = async (endPoint: string, method: "GET" | "PUT" | "POST", data?: {}) => {
        const baseUrl = "https://api.spotify.com/v1/";

        const requestOptions = {
            method: method,
            headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };

        const requestOptionsGET = {
            method: method,
            headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}
        };

        return await fetch(baseUrl + endPoint, data ? requestOptions : requestOptionsGET)
            .then(response => {

                if (response.status === 204)
                    console.warn("Playback not available or active", endPoint, response)
                else if (response.status === 401) {
                    console.warn("Unauthorized", endPoint, response)
                    logout();
                } /*else
                    console.log(endPoint, response)*/

                return response.json()
            }).catch(() => {
                return ""
            })
    }

    const logout = () => {
        setToken("")
        window.localStorage.removeItem("token")
    }

    const spotifyButton = (text: string, props?: ButtonProps) => {
        return (
            <Button {...props} sx={{
                color: "white",
                backgroundColor: "#1db954",
                '&:hover': {
                    backgroundColor: "#22d360",
                },
            }}>
                <Box component="img" src="Spotify_Icon_RGB_White.png" sx={{marginRight: "10px", width: "20px"}}/>
                {text}
            </Button>
        )
    }

    const logInButton = () => {
        return (spotifyButton("Login to Spotify", {href: `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}`}));
    }

    const logOutButton = () => {
        return (spotifyButton("Logout", {onClick: logout}));
    }

    const formatTime = (time: number | undefined) => {
        if (!time)
            return "0:00";

        let minutes = Math.floor(time / 1000 / 60);
        let seconds = Math.round(time / 1000 - minutes * 60);

        if (seconds === 60) {
            minutes += 1;
            seconds = 0;
        }

        const leadingZero = seconds < 10 ? "0" : "";

        return minutes + ":" + leadingZero + seconds;
    }

    return {
        token,
        setToken,
        playBackState,
        setPlayBackState,
        fetchSpotify,
        logInButton,
        logOutButton,
        formatTime
    };
}
