// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDk-KkslO-B-tPAoB-Ef82bHvX0dQjR6Cw",
    authDomain: "traaga-e3de7.firebaseapp.com",
    projectId: "traaga-e3de7",
    storageBucket: "traaga-e3de7.appspot.com",
    messagingSenderId: "856223956253",
    appId: "1:856223956253:web:0364ab3a759033b2e535f4",
    measurementId: "G-BBMHE4MBNP"
};

export default function useFirebase() {

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const functions = getFunctions();

    const sayHello = httpsCallable(functions, "sayHello");
    const getSongs = httpsCallable(functions, "getSongs");
    const getRandom = httpsCallable(functions, "getRandom");
    const getPlaylistInfo = httpsCallable(functions, "getPlaylistInfo");
    const handleUpdateDatabase = httpsCallable(functions, "handleUpdateDatabase");

    return {
        sayHello,
        getSongs,
        getRandom,
        getPlaylistInfo,
        handleUpdateDatabase
    }
}
