import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polyline
} from "react-leaflet";
import {LatLngExpression, divIcon} from "leaflet";
import markers from "../data.json";
import useMarkerCreator from "../Other/useMarkerCreator";
import MapCenterHandler from "../Other/MapCenterHandler";
import UserLocation from "./UserLocation";

const Map = () => {

    const {markerHTMLElement} = useMarkerCreator();

    const lngItem = sessionStorage.getItem("centerLongitude");
    const latItem = sessionStorage.getItem("centerLatitude");

    const longitude = lngItem ? lngItem : "";
    const latitude = latItem ? latItem : "";

    const defaultPos: LatLngExpression | undefined = [58.380168046718595, 26.722494387805813];
    const homePos: LatLngExpression | undefined = [58.37241237504033, 26.710613706235304];

    const getCurrentPosition = () => {
        const pos: LatLngExpression | undefined = [
            parseFloat(latitude),
            parseFloat(longitude)
        ]
        return pos;
    }

    const historyIncludesID = (id: number, history: number[][]) => {
        const historyIDs = history.map(h => {
            return h[2];
        });
        return historyIDs.includes(id);
    }

    const getNearestPoint = (point: number[], history: number[][]) => {
        let smallestDistance = 1;
        let nearestPoint = [0, 0];

        markers.forEach(m => {

            const d = ((point[0] - m.longitude) ** 2 + (point[1] - m.latitude) ** 2) ** 0.5;
            const el = [m.longitude, m.latitude, m.id];

            if (d < smallestDistance && d !== 0 && !historyIncludesID(el[2], history)) {
                smallestDistance = d;
                nearestPoint = el;
            }
        });
        return nearestPoint;
    }


    let path = [[homePos[0], homePos[1], 0]];

    for (let i = 0; i < markers.length; i++) {
        path = [...path, getNearestPoint(path[i], path)];
    }

    const polyline: LatLngExpression[] | LatLngExpression[][] = path.map(p => {
        return [p[0], p[1]];
    });

    const limeOptions = {color: 'lime'}

    return (
        <MapContainer id="map" center={lngItem != null ? getCurrentPosition() : defaultPos} zoom={14} scrollWheelZoom={true}>
            <MapCenterHandler/>
            <UserLocation/>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers.length > 0 &&
                <Polyline pathOptions={limeOptions} positions={polyline}/>
            }
            {markers.map(marker =>
                <Marker key={marker.id} position={[marker.longitude, marker.latitude]}
                        icon={divIcon({className: "custom icon", html: markerHTMLElement({label: marker.id.toString()})})}
                >
                    <Popup className={"customPopup"}>
                        ID: {marker.id} <br/>
                        Q: {marker.question} <br/>
                        A: {marker.answer} <br/>
                        {marker.longitude} <br/>
                        {marker.latitude} <br/>
                    </Popup>
                </Marker>
            )}
        </MapContainer>
    )
};

export default Map;
