import {AxisOptions, Chart} from 'react-charts'
import React from "react";
import {Box} from "@mui/material";
import {SongItem} from "../Other/useSpotify";

type MyDatum = { x: number, y: number }

interface LineProps {
    songs: SongItem[]
}

const Line = ({songs}: LineProps) => {

    const tempos = songs.map(song => Math.round(song.tempo));

    tempos.sort(function(a, b) {
        return a - b;
    })

    const tempoSet = new Set(tempos);
    let tempoData: MyDatum[] = [];

    const itemCounter = (array: number[], item: number) => {
        return array.filter((currentItem) => currentItem == item).length;
    };

    tempoSet.forEach(el => {
        tempoData.push({
            x: el,
            y: itemCounter(tempos, el)
        } as MyDatum)
    })

    const data = [
        {
            data: tempoData
        },
    ]

    const primaryAxis = React.useMemo(
        (): AxisOptions<MyDatum> => ({
            getValue: datum => datum.x,
        }),
        []
    )

    const secondaryAxes = React.useMemo(
        (): AxisOptions<MyDatum>[] => [
            {
                getValue: datum => datum.y,
            },
        ],
        []
    )

    return (
        <Box sx={{height: "150px", width: "300px", marginLeft: "100px"}}>
            <Chart
                options={{
                    data,
                    primaryAxis,
                    secondaryAxes,
                }}
            />
        </Box>
    )
}

export default Line;
